import { RepositoryFactory } from '@/repository/RepositoryFactory'

const MerchantRepository = RepositoryFactory.get('merchant')

export default async function getMerchantList() {
  const merchantOptions = localStorage.getItem('merchant_list')
    ? JSON.parse(localStorage.getItem('merchant_list'))
    : (await MerchantRepository.getMerchantListForDropdown()).data.data

  if (!localStorage.getItem('merchant_list')) {
    localStorage.setItem('merchant_list', JSON.stringify(merchantOptions))
  }

  return merchantOptions
}
